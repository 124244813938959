import React from "react"
import { graphql } from "gatsby"
// import Img from "gatsby-image"
import Moment from "react-moment"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/Hero"
import BlockContentPrimary from "../components/blockContentPrimary"

import Files from "../components/Files"
import TagsSection from "../components/tags/TagsSection"
import ButtonTemplate from "../components/buttons/ButtonTemplate"

const BlogPostTemplate = ({ data }) => {
  const createdDate = data.sanityBlogPost._createdAt || null
  const updatedDate = data.sanityBlogPost._updatedAt || null
  const rawPostContent = data.sanityBlogPost._rawPostContent
  const {
    title,
    featureImage,
    files,
    publishedDate,
    refs,
    inNewsFeed,
  } = data.sanityBlogPost

  // only assign if the data exists
  // wasn't working: const fluid = featureImage ? featureImage.asset : null

  let hero = null
  if (featureImage == null || featureImage.asset == null) {
    hero = null
  } else {
    hero = featureImage.asset
  }

  return (
    <Layout>
      <SEO title={`${data.sanityBlogPost.title}`} />
      <div className="grid px-5 ">
        {/* Only show hero if feature image provided */}
        {featureImage && featureImage.asset && (
          <Hero englishTitle={title} heroImage={hero} />
        )}
        {/* OR: */}

        <div className="mx-auto font-light text-base max-w-6xl  w-full">
          {/* Only show this if there is NO feature image */}
          {(!featureImage || !featureImage.asset) && (
            <h1 className="mt-10 lg:mt-32 text-center mx-3">{title}</h1>
          )}
          <div className="blogPost  ">
            <div className="font-heading text-blue-medium my-8 ">
              Published:&nbsp;
              {publishedDate || createdDate}
            </div>
            <BlockContentPrimary blockData={rawPostContent} />
          </div>
          {files.length >= 1 && <Files files={files} />}
        </div>

        {inNewsFeed ? (
          <div className="flex justify-center my-8">
            <ButtonTemplate
              to="/news"
              text="All News Posts"
              className="text-white uppercase tracking-wide mt-8"
              index={1}
            />
          </div>
        ) : null}

        {/* TAGS SECTION */}

        <TagsSection refs={refs} />
        <ButtonTemplate
          to="/communities"
          text="All Communities"
          className="text-white uppercase tracking-wide mt-10 justify-self-center"
          index={1}
        />
      </div>
    </Layout>
  )
}

// "$slug: String!" means that we are pulling in the slug from the context (passed in BlogPostTemplate) and that it must be a string value
// "(slug: { current: { eq: $slug } })" means that the slug from the query needs to match the value from the context, so that we get info for the correct page.
//see video 16 of LUT gatsby/shopify

export const query = graphql`
  query sanityBlogPostQuery($slug: String!) {
    sanityBlogPost(slug: { current: { eq: $slug } }) {
      id
      _createdAt(formatString: "MM/DD/YYYY")
      _updatedAt(formatString: "MM/DD/YYYY")
      title
      summary
      inNewsFeed
      files {
        asset {
          id
          url
          originalFilename
        }
      }
      _rawPostContent(resolveReferences: { maxDepth: 10 })
      featureImage {
        asset {
          fluid {
            ...GatsbySanityImageFluid
          }
        }
      }
      publishedDate(formatString: "MM/DD/YYYY")

      refs {
        communityReference {
          communityName
          slug {
            current
          }
        }
        departmentReference {
          departmentName
          slug {
            current
          }
        }
      }
    }
  }
`

export default BlogPostTemplate
